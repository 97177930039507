import { Component, inject, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '../../services/login.service';
import { RouterLink } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [RouterLink, RippleModule, StyleClassModule, MenuComponent],
})
export class HeaderComponent {
  private loginService = inject(LoginService);

  constructor(@Inject(DOCUMENT) private document: any) {
    if (typeof window !== 'undefined') {
      this.loginService.currentUser$.subscribe();
    }
  }

  closeMenu() {
    const element = this.document.querySelector('.menu-wrapper');
    element.classList.add('hidden');
  }
}
