import { Component, Inject, inject } from '@angular/core';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router, RouterLink } from '@angular/router';
import { SocialBlockComponent } from '../../components/social-block/social-block.component';
import { LoginService } from '../../services/login.service';
import { debounceTime, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RouterLink, SocialBlockComponent],
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss',
})
export class LoginModalComponent {
  private readonly router = inject(Router);
  private readonly dialogRef = inject(DynamicDialogRef);
  private readonly loginService = inject(LoginService);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.setViewportHeight();

    fromEvent(window, 'resize')
      .pipe(debounceTime(200), takeUntilDestroyed())
      .subscribe(() => this.setViewportHeight());
  }

  private setViewportHeight(): void {
    const vh = window.innerHeight * 0.01;
    this.document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  login() {
    this.loginService.login().subscribe(() => {
      this.dialogRef.close();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  openLink(url: string) {
    this.router.navigateByUrl(url);
    this.closeModal();
  }
}
