import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { MessageService } from 'primeng/api';
import { LoginService } from './shared/services/login.service';
import { DialogService } from 'primeng/dynamicdialog';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'my-tattoo-app' }),
      AppRoutingModule,
      AngularFireModule.initializeApp(environment.firebase),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideFunctions(() => getFunctions()),
    MessageService,
    LoginService,
    DialogService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideClientHydration(),
  ],
};
