import { Injectable } from '@angular/core';
import { IGenerationResponse } from '../types/generation';
import { ICategory } from '../types/category';
import { IStyleDetailsResponse, IStyleResponse } from '../types/style';
import { ITagDetails } from '../types';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public explorePage: {
    filter: any;
    details: IGenerationResponse | null;
    activePage: number | null;
  } = {
    filter: {},
    details: null,
    activePage: null,
  };
  public ideaPage: {
    items: ICategory[] | null;
    aloneTags: ICategory[] | null;
    details: ITagDetails | null;
  } = {
    items: null,
    aloneTags: null,
    details: null,
  };
  public stylesPage: {
    stylesList: IStyleResponse | null;
    details: IStyleDetailsResponse | null;
    activePage: number | null;
  } = {
    stylesList: null,
    details: null,
    activePage: null,
  };
  public partOfBody: {
    list: IStyleResponse | null;
    details: IStyleDetailsResponse | null;
    activePage: number | null;
  } = {
    list: null,
    details: null,
    activePage: null,
  };
}
