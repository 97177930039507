import { Component, Inject, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { ScrollService } from './shared/services/scroll.service';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet>`,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private meta = inject(Meta);
  private router = inject(Router);
  private scrollService = inject(ScrollService);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (!environment.production) {
      this.meta.updateTag({ name: 'robots', content: 'noindex' });
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (isPlatformBrowser(this.platformId)) {
        if (event instanceof NavigationStart) {
          const url = this.router.url;
          const scrollPosition = window.scrollY;
          this.scrollService.setScrollPosition(url, scrollPosition);
        } else if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects;
          const scrollPosition = this.scrollService.getScrollPosition(url);
          window.scrollTo(0, scrollPosition);
        }
      }
    });
  }
}
