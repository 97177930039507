<div class="section-wrapper px-3 md:px-4 lg:py-1 xl:px-6">
  <div class="grid grid-nogutter px-1 pt-4 pb-1 lg:px-0 lg:py-3">
    <div
      class="col-12 flex align-items-center md:flex-column lg:flex-row justify-content-between relative text-gray-primary lg:static menu"
    >
      <a routerLink="/">
        <img
          class="h-2rem w-2rem xs:h-3_5rem xs:w-3_5rem cursor-pointer transition-all transition-duration-300 transition-ease-in"
          src="assets/img/logo.svg"
          width="58px"
          height="58px"
          alt="Logo"
        />
      </a>

      <span
        pRipple
        class="cursor-pointer block lg:hidden text-black-primary"
        pStyleClass="@next"
        enterFromClass="hidden"
        leaveToClass="hidden"
        [hideOnOutsideClick]="true"
      >
        <i class="pi pi-bars text-4xl"></i>
      </span>

      <app-menu
        (click)="closeMenu()"
        class="menu-wrapper align-items-center flex-grow-1 justify-content-between hidden absolute lg:hidden surface-overlay top-100"
        [displayAuth]="true"
      ></app-menu>
      <app-menu class="lg:block hidden" [displayAuth]="true"></app-menu>
    </div>
  </div>
</div>
