<div class="">
  <app-header></app-header>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <p-toast></p-toast>
</div>
