<nav class="lg:flex lg:flex-row lg:align-items-center">
  <ul class="list-none p-0 m-0 pb-2 lg:pb-0 flex lg:align-items-center flex-column lg:flex-row cursor-pointer">
    <li>
      <a
        routerLink="/explore"
        routerLinkActive="text-pink-middle"
        pRipple
        [ngClass]="{ 'text-white': !displayAuth }"
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Explore
      </a>
    </li>
    <li>
      <a
        routerLink="/ideas"
        routerLinkActive="text-pink-middle"
        [ngClass]="{ 'text-white': !displayAuth }"
        pRipple
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Ideas
      </a>
    </li>
    <li>
      <a
        routerLink="/styles"
        routerLinkActive="text-pink-middle"
        [ngClass]="{ 'text-white': !displayAuth }"
        pRipple
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Styles
      </a>
    </li>
    <li>
      <a
        routerLink="/pricing"
        routerLinkActive="text-pink-middle"
        [ngClass]="{ 'text-white': !displayAuth }"
        pRipple
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Pricing
      </a>
    </li>
    <!--    <li [routerLink]="['part-of-body']" routerLinkActive="text-pink-middle">-->
    <!--      <a [ngClass]="{'text-white': !displayAuth}" pRipple class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150">-->
    <!--        <span>Body</span>-->
  <!--      </a>-->
<!--    </li>-->

@if (displayAuth) {
  @if (!isAuthUser) {
    <li (click)="login()">
      <span
        pRipple
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Sign In
      </span>
    </li>
  }
  @if (isAuthUser) {
    <li>
      <a
        routerLink="/account"
        routerLinkActive="text-pink-middle"
        pRipple
        class="flex px-0 py-2 lg:px-5 hover:text-pink-middle font-medium transition-colors transition-duration-150"
        >
        Account
      </a>
    </li>
  }
}
</ul>

<button
  pButton
  pRipple
  label="Create tattoo"
  type="button"
  class="p-button-raised small-btn lg:ml-5 text-sm md:px-4"
  (click)="createTattoo()"
></button>
</nav>
