import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store.service';

@Injectable({ providedIn: 'root' })
export class PageParamResolver {
  constructor(
    private router: Router,
    private storeService: StoreService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const pageParam = route.queryParamMap.get('page');
    if (pageParam === null || pageParam === '0' || isNaN(parseInt(pageParam))) {
      return this.router.navigate(['/explore'], {
        queryParams: { page: this.storeService.explorePage.activePage || 1 },
      });
    }

    return true;
  }
}
