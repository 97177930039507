export const environment = {
  firebase: {
    apiKey: 'AIzaSyA0h0HpI8PTnQILkiNFCvUu1FLqFvsKTzg',
    authDomain: 'zerolimit-tattoo.firebaseapp.com',
    projectId: 'zerolimit-tattoo',
    storageBucket: 'zerolimit-tattoo.appspot.com',
    messagingSenderId: '231062469912',
    appId: '1:231062469912:web:e47c0010066c45b892326d',
  },
  BASE_URL: 'https://api.tattooai.ink/v1',
  crispKey: '',
  website_url: 'https://tattooai.ink',
  production: false,
  devMode: true,
  local: false,
};
