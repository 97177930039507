import { Component, Input } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router, RouterModule } from '@angular/router';
import { NgClass } from '@angular/common';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [RouterModule, NgClass, RippleModule, ButtonModule],
})
export class MenuComponent {
  @Input() displayAuth!: boolean;

  user!: any;

  constructor(
    public loginService: LoginService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loginService.user$$.subscribe((user: any) => {
      this.user = user;
    });
  }

  createTattoo() {
    if (!this.user) {
      this.loginService.showLoginModal();
    } else {
      this.router.navigate(['./dashboard']);
    }
  }

  get isAuthUser() {
    return this.user?.email;
  }

  login() {
    this.loginService.showLoginModal();
  }
}
