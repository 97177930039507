import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageParamResolver } from './shared/router-resolvers/router-setup';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./landing/landing.component').then((m) => m.LandingComponent),
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'ideas',
        loadChildren: () => import('./ideas/ideas.module').then((m) => m.IdeasModule),
      },
      {
        path: 'categories/:id',
        loadComponent: () =>
          import('./categories/category-details/category-details.component').then((m) => m.CategoryDetailsComponent),
      },
      {
        path: 'pricing',
        loadComponent: () => import('./pricing/pricing.component').then((m) => m.PricingComponent),
      },
      {
        path: 'account',
        loadComponent: () => import('./account/account.component').then((m) => m.AccountComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'explore',
        loadComponent: () =>
          import('./explore/explore-list/explore-list.component').then((m) => m.ExploreListComponent),
        resolve: { pageParam: PageParamResolver },
      },
      {
        path: 'styles',
        loadChildren: () => import('./styles/styles.module').then((m) => m.StylesModule),
      },
      {
        path: 'part-of-body',
        loadChildren: () => import('./part-of-body/part-of-body.module').then((m) => m.PartOfBodyModule),
      },
      {
        path: 'challenge',
        loadComponent: () => import('./challenge/challenge.component').then((m) => m.ChallengeComponent),
      },
      {
        path: 'privacy-policy',
        loadComponent: () => import('./privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
      },
      {
        path: 'terms-of-use',
        loadComponent: () => import('./terms-of-use/terms-of-use.component').then((m) => m.TermsOfUseComponent),
      },
      {
        path: 'tattoo/:id',
        loadComponent: () => import('./tattoo-details/tattoo-details.component').then((m) => m.TattooDetailsComponent),
      },
      {
        path: '404',
        loadComponent: () => import('./not-found/not-found.component').then((m) => m.NotFoundComponent),
      },
    ],
  },
  {
    path: 'campaign/:id',
    loadComponent: () => import('./campaign/campaign.component').then((m) => m.CampaignComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
